import './css/bootstrap.min.css';



function App() {
  return (
    <div>
      <div className="starter-template">
        <h1>Mark Cerritelli</h1>
        <p className="lead">Some dude on the internet.<br/> Welcome!</p>
      </div>

      <div className="text-center">

        <div>
          <h3>Friends</h3>
          <a href="http://freezebeam.com"><img src="images/tyrantFemaleBow.png" width="128" height="128"/></a>
        </div>

        <div>
          <h3>iOS Apps</h3>
          <a href="cointoss.html"><img src="images/CoinAndDice.png" width="128" height="128"/></a>
        </div>

        <div>
          <h3>Personal Information</h3>
          <p>Contact: first name at cerritelli dot com</p>
          <a href="resume.pdf">Resume</a>
        </div>
      </div>


    </div>
  );
}

export default App;
